import './App.css';
import OrderPage from './pages/OrderPage';

import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme(); // You can customize this theme

function App() {
  return (
    <ThemeProvider theme={theme}>
      <OrderPage />
    </ThemeProvider>
  );
}

export default App;