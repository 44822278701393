const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

export const createPaymentIntent = async (amount) => {
  try {
    const response = await fetch(`${API_URL}/stripe/create-payment-intent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ amount: Math.round(amount * 100) }), // Convert to cents and ensure it's an integer
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to create payment intent');
    }
    const data = await response.json();
    return data.clientSecret;
  } catch (error) {
    console.error('Error in createPaymentIntent:', error);
    throw error;
  }
};

export const placeOrder = async (orderData) => {
  try {
    console.log('Sending order data to server:', JSON.stringify(orderData, null, 2));
    const response = await fetch(`${API_URL}/orders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(orderData),
    });
    
    const data = await response.json();
    
    if (!response.ok) {
      throw new Error(data.error || 'Failed to place order');
    }
    
    console.log('Server response:', data);
    return data;
  } catch (error) {
    console.error('Error in placeOrder:', error);
    throw error;
  }
};

export const getOrderHistory = async (email) => {
  try {
    const response = await fetch(`${API_URL}/orders/${email}`);
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to fetch order history');
    }
    return await response.json();
  } catch (error) {
    console.error('Error in getOrderHistory:', error);
    throw error;
  }
};

export const getMenuItems = async () => {
  try {
    const response = await fetch(`${API_URL}/menu`);
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to fetch menu items');
    }
    return await response.json();
  } catch (error) {
    console.error('Error in getMenuItems:', error);
    throw error;
  }
};

// Add any other API utility functions here

export const handleApiError = (error) => {
  // You can customize this function to handle errors in a way that fits your application
  console.error('API Error:', error);
  // You might want to show a notification to the user or perform some other action
};