import React, { useState, useEffect, useRef } from 'react';
import {
  Container, Typography, Box, Button, Card, CardContent, CardMedia,
  IconButton, Snackbar, Alert, CircularProgress, Grid, Paper, TextField,FormHelperText,
  Divider, useTheme, ThemeProvider, createTheme, AppBar, Toolbar, Modal, Dialog, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { styled, keyframes } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { createPaymentIntent, placeOrder } from '../utils/api-utility';
import { Helmet } from 'react-helmet';

const stripePromise = loadStripe('pk_live_51Pj0mEC3ozJciVZF63AqfJrkVHlC5z7OWdYy2OtKdWqSU0EBBE6IZbfkezVLyBJWhzJiPO0CM3lYp8DoCG5XIpos005yFfe5Ab');

const theme = createTheme({
  palette: {
    primary: {
      main: '#004225',
      light: '#3e6f5c',
      dark: '#002b19',
    },
    secondary: {
      main: '#F16B29',
      light: '#ff9c5c',
      dark: '#b83c00',
    },
  },
});

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  animation: `${fadeIn} 0.5s ease-out`,
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4],
  },
}));

const StyledQuantityInput = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.light,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5),
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 100,
  height: 100,
  marginRight: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  '&:hover .zoom-icon': {
    opacity: 1,
  },
}));

const ZoomIconOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(0, 0, 0, 0.5)',
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',
  cursor: 'pointer',
}));

const PriceTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: 'bold',
}));

const FloatingBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
}));
const Header = ({ logoUrl, onAllergenAdviceClick }) => (
  <AppBar position="static" color="primary">
    <Toolbar>
      <img src={logoUrl} alt="Logo" style={{ height: 40, marginRight: 16 }} />
      <Button color="inherit" href="/">Menu</Button>
      <Button color="inherit" href="https://staging.fedras.co.uk/" target="_blank" rel="noopener noreferrer">Fedras Site</Button>
      <Button color="inherit" onClick={onAllergenAdviceClick}>Allergen Advice</Button>
    </Toolbar>
  </AppBar>
);

const Footer = ({ logoUrl, onAllergenAdviceClick }) => (
  <Box sx={{ bgcolor: 'primary.main', color: 'white', py: 3, mt: 4 }}>
    <Container>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={4}>
          <img src={logoUrl} alt="Logo" style={{ height: 40 }} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box display="flex" justifyContent="center">
            <Button color="inherit" href="/">Menu</Button>
            <Button color="inherit" href="https://staging.fedras.co.uk/" target="_blank" rel="noopener noreferrer">Fedras Site</Button>
            <Button color="inherit" onClick={onAllergenAdviceClick}>Allergen Advice</Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="body2" align="right">
            © {new Date().getFullYear()} Fedras. All rights reserved.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  </Box>
);

const QuantityInput = ({ value, onChange }) => (
  <StyledQuantityInput>
    <StyledIconButton onClick={() => onChange(Math.max(0, value - 1))} size="small">
      <RemoveIcon />
    </StyledIconButton>
    <Typography sx={{ width: '40px', textAlign: 'center', color: 'white' }}>{value || 0}</Typography>
    <StyledIconButton onClick={() => onChange(value + 1)} size="small">
      <AddIcon />
    </StyledIconButton>
  </StyledQuantityInput>
);

const FillingOption = ({ name, dietaryInfo, quantity, onQuantityChange, maxQuantity }) => (
  <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
    <Box>
      <Typography variant="body1">{name}</Typography>
      <Box>
        {dietaryInfo.map(info => (
          <Typography key={info} variant="caption" sx={{ mr: 1, bgcolor: 'secondary.light', color: 'white', p: 0.5, borderRadius: 1 }}>
            {info}
          </Typography>
        ))}
      </Box>
    </Box>
    <QuantityInput
      value={quantity}
      onChange={(value) => onQuantityChange(Math.min(value, maxQuantity))}
    />
  </Box>
);

const MenuSection = ({ title, items, order, updateQuantity, updateFilling, updateOption, onImageClick }) => (
  <Box mb={4}>
    <Typography variant="h5" gutterBottom color="primary" sx={{ borderBottom: '2px solid', borderColor: 'primary.main', pb: 1 }}>
      {title === "Breakfast Menu" && "🍳 "}
      {title === "Lunch Menu" && "🥪 "}
      {title === "Drinks" && "🥤 "}
      {title === "Extras" && "🍽️ "}
      {title}
    </Typography>
    {items.map((item, index) => (
      <React.Fragment key={item.id}>
        <StyledCard>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item>
                <ImageContainer onClick={() => onImageClick(item.imageUrl)}>
                  <CardMedia
                    component="img"
                    height="100"
                    image={item.imageUrl || 'placeholder.jpg'}
                    alt={item.name}
                  />
                  <ZoomIconOverlay className="zoom-icon">
                    <ZoomInIcon style={{ color: 'white' }} />
                  </ZoomIconOverlay>
                </ImageContainer>
              </Grid>
              <Grid item xs>
                <Typography variant="h6" color="primary">{item.name}</Typography>
                <PriceTypography variant="subtitle1">£{item.price.toFixed(2)} per person</PriceTypography>
                <Typography variant="body2" color="text.secondary" gutterBottom>{item.description}</Typography>
                <Box display="flex" justifyContent="flex-end" mb={2}>
                  <QuantityInput
                    value={order[item.id].quantity}
                    onChange={(value) => updateQuantity(item.id, value)}
                  />
                </Box>
                {item.fillings && order[item.id].quantity > 0 && (
                  <Box>
                    <Typography variant="subtitle1" gutterBottom>
                      Choose your fillings ({Object.values(order[item.id].fillings).reduce((sum, value) => sum + value, 0)} / {order[item.id].quantity})
                    </Typography>
                    {Object.entries(item.fillings).map(([filling, dietaryInfo]) => (
                      <FillingOption
                        key={filling}
                        name={filling}
                        dietaryInfo={dietaryInfo}
                        quantity={order[item.id].fillings[filling] || 0}
                        onQuantityChange={(value) => updateFilling(item.id, filling, value)}
                        maxQuantity={order[item.id].quantity - Object.values(order[item.id].fillings).reduce((sum, val) => sum + val, 0) + (order[item.id].fillings[filling] || 0)}
                      />
                    ))}
                  </Box>
                )}
                {item.options && order[item.id].quantity > 0 && (
                  <Box>
                    <Typography variant="subtitle1" gutterBottom>
                      Choose your options ({Object.values(order[item.id].options).reduce((sum, value) => sum + value, 0)} / {order[item.id].quantity})
                    </Typography>
                    {item.options.map((option) => (
                      <FillingOption
                        key={option}
                        name={option}
                        dietaryInfo={[]}
                        quantity={order[item.id].options[option] || 0}
                        onQuantityChange={(value) => updateOption(item.id, option, value)}
                        maxQuantity={order[item.id].quantity - Object.values(order[item.id].options).reduce((sum, val) => sum + val, 0) + (order[item.id].options[option] || 0)}
                      />
                    ))}
                  </Box>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </StyledCard>
        {index < items.length - 1 && <Divider sx={{ my: 2 }} />}
      </React.Fragment>
    ))}
  </Box>
);

const OrderSummary = ({ order, menuItems, totalPrice, onCheckout, isCheckoutDisabled }) => {
  const renderOrderItem = (item, quantity, fillings, options) => {
    if (quantity === 0) return null;
    return (
      <Box key={item.id} mb={1}>
        <Typography variant="body2">
          {item.name} x{quantity} - <PriceTypography component="span">£{(item.price * quantity).toFixed(2)}</PriceTypography>
        </Typography>
        {fillings && Object.entries(fillings).map(([filling, qty]) => (
          qty > 0 && (
            <Typography key={filling} variant="body2" pl={2}>
              {filling}: {qty}
            </Typography>
          )
        ))}
        {options && Object.entries(options).map(([option, qty]) => (
          qty > 0 && (
            <Typography key={option} variant="body2" pl={2}>
              {option}: {qty}
            </Typography>
          )
        ))}
      </Box>
    );
  };

  const renderDrinks = () => {
    const orderedDrinks = Object.entries(order.drinks).filter(([_, quantity]) => quantity > 0);
    if (orderedDrinks.length === 0) return null;
    return (
      <>
        <Typography variant="subtitle1" gutterBottom>Drinks:</Typography>
        {orderedDrinks.map(([drinkId, quantity]) => {
          const drink = menuItems.drinks.find(d => d.id === drinkId);
          return (
            <Typography key={drinkId} variant="body2">
              {drink.name} x{quantity} - <PriceTypography component="span">£{(drink.price * quantity).toFixed(2)}</PriceTypography>
            </Typography>
          );
        })}
      </>
    );
  };

  const renderExtras = () => {
    const orderedExtras = Object.entries(order.extras).filter(([_, quantity]) => quantity > 0);
    if (orderedExtras.length === 0) return null;
    return (
      <>
        <Typography variant="subtitle1" gutterBottom>Extras:</Typography>
        {orderedExtras.map(([extraId, quantity]) => {
          const extra = menuItems.extras.find(e => e.id === extraId);
          return (
            <Typography key={extraId} variant="body2">
              {extra.name} x{quantity} - <PriceTypography component="span">£{(extra.price * quantity).toFixed(2)}</PriceTypography>
            </Typography>
          );
        })}
      </>
    );
  };

  return (
    <>
      <Typography variant="h6" gutterBottom color="primary">Order Summary</Typography>
      {menuItems.breakfast.map(item => renderOrderItem(item, order[item.id].quantity, order[item.id].fillings, order[item.id].options))}
      {menuItems.lunch.map(item => renderOrderItem(item, order[item.id].quantity, order[item.id].fillings))}
      {renderDrinks()}
      {renderExtras()}
      <Box mt={2}>
        <Typography variant="h6">Total: <PriceTypography component="span">£{totalPrice.toFixed(2)}</PriceTypography></Typography>
      </Box>
      <Button
        onClick={onCheckout}
        disabled={isCheckoutDisabled}
        variant="contained"
        color="secondary"
        fullWidth
        sx={{ mt: 2 }}
      >
        Proceed to Checkout
      </Button>
    </>
  );
};

const CheckoutForm = ({ order, menuItems, totalPrice, onSuccess, onError }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [bookingId, setBookingId] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      setLoading(false);
      onError("Stripe has not been properly initialized.");
      return;
    }

    try {
      const clientSecret = await createPaymentIntent(totalPrice);

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: name,
            email: email,
          },
        }
      });

      if (result.error) {
        onError(result.error.message);
      } else {
        const orderData = {
          order: {
            orderItems: [
              ...menuItems.breakfast,
              ...menuItems.lunch
            ].filter(item => order[item.id]?.quantity > 0)
              .map(item => ({
                name: item.name,
                quantity: order[item.id].quantity,
                price: item.price,
                fillings: order[item.id].fillings,
                options: order[item.id].options
              })),
            drinks: Object.fromEntries(
              Object.entries(order.drinks)
                .filter(([_, quantity]) => quantity > 0)
            ),
            extras: Object.fromEntries(
              Object.entries(order.extras)
                .filter(([_, quantity]) => quantity > 0)
            )
          },
          totalPrice,
          customerName: name,
          customerEmail: email,
          paymentIntentId: result.paymentIntent.id,
          bookingId
        };

        console.log('Sending order data:', JSON.stringify(orderData, null, 2));

        try {
          const response = await placeOrder(orderData);
          console.log('Order placed successfully:', response);
          
          if (response.message.includes("failed to send confirmation email")) {
            onSuccess('Your order has been placed successfully, but there was an issue sending the confirmation email. Please check your order details in your account or contact customer support.');
          } else {
            onSuccess('Your order has been placed successfully and a confirmation email has been sent.');
          }
        } catch (error) {
          console.error('Error in placeOrder:', error);
          if (error.message.includes("failed to send confirmation email")) {
            onSuccess('Your order has been placed successfully, but there was an issue sending the confirmation email. Please check your order details in your account or contact customer support.');
          } else {
            onError('An error occurred while processing your order: ' + error.message);
          }
        }
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      onError('An error occurred while processing your order: ' + error.message);
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Booking ID"
        value={bookingId}
        onChange={(e) => setBookingId(e.target.value)}
        fullWidth
        margin="normal"
        required
      />
      <FormHelperText>
        Please check your booking room confirmation email to find your booking ID.
      </FormHelperText>
      <Box my={2}>
        <CardElement options={{
          style: { base: { fontSize: '16px' } },
          hidePostalCode: true,
        }} />
      </Box>
      <Button
        type="submit"
        disabled={!stripe || loading}
        variant="contained"
        color="secondary"
        fullWidth
      >
        {loading ? <CircularProgress size={24} /> : `Pay £${totalPrice.toFixed(2)}`}
      </Button>
    </form>
  );
};



const AllergenInfoBox = () => (
  <>
    <Typography variant="h6" gutterBottom color="primary">Allergen Information</Typography>
    <Grid container spacing={1}>
      {[
        ['E', 'Eggs'],
        ['F', 'Fish'],
        ['G', 'Cereals Containing Gluten'],
        ['M', 'Milk'],
        ['N', 'Nuts'],
        ['S', 'Soya'],
        ['SD', 'Sulphur Dioxide'],
        ['SS', 'Sesame Seeds']
      ].map(([code, allergen]) => (
        <Grid item xs={6} key={code}>
          <Box display="flex" alignItems="center">
            <Box
              sx={{
                minWidth: 24,
                height: 24,
                borderRadius: '50%',
                bgcolor: 'secondary.main',
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mr: 1,
                fontSize: '0.75rem',
                fontWeight: 'bold'
              }}
            >
              {code}
            </Box>
            <Typography variant="body2">{allergen}</Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  </>
);

const OrderPage = () => {
  const [order, setOrder] = useState({
    classicBreakfast1: { quantity: 0, fillings: {} },
    classicBreakfast2: { quantity: 0, fillings: {} },
    classicBreakfast3: { quantity: 0 },
    croissants: { quantity: 0, options: {} },
    danishPastries: { quantity: 0 },
    classicLunch1: { quantity: 0, fillings: {} },
    classicLunch2: { quantity: 0, fillings: {} },
    classicLunch3: { quantity: 0, fillings: {} },
    drinks: {},
    extras: {}
  });
  const [error, setError] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showCheckout, setShowCheckout] = useState(false);
  const [zoomedImage, setZoomedImage] = useState(null);
  const [allergenAdviceOpen, setAllergenAdviceOpen] = useState(false);
  const [orderSummaryHeight, setOrderSummaryHeight] = useState(0);
  const orderSummaryRef = useRef(null);

  const logoUrl = "https://staging.fedras.co.uk/wp-content/uploads/2024/03/Fedras_Gold-Logo_PNG.png";
  const allergenAdviceText = "Allergen information: At Fedras Coffee and its partners, the safety of our customers is our highest priority. We operate an artisan-style kitchen and bakery where all allergens are present at all times. While we take every precaution to prevent cross-contamination, we cannot guarantee that any food item is completely free of specific allergens. If you or someone you're providing food for has an allergy, it's crucial to be aware of this and share this information with anyone concerned.";


  const menuItems = {
    breakfast: [
      {
        id: 'classicBreakfast1',
        name: 'Classic Breakfast 1',
        price: 7.00,
        description: '1 x Mini filled breakfast bagel per person, 1 x Mini croissants/danish per person, 1 x Fruit skewer per person',
        imageUrl: 'https://staging.fedras.co.uk/wp-content/uploads/2024/08/classic-breakfast-600x600.jpg',
        fillings: {
          'Ham & Cheddar': ['E', 'G', 'M', 'S'],
          'Smoked Salmon & Cream Cheese': ['E', 'F', 'G', 'M', 'S'],
          'Mature Cheddar & Tomato': ['E', 'G', 'M', 'S'],
          'Hummus & Roasted Peppers': ['G', 'SD', 'SS', 'VG']
        }
      },
      {
        id: 'classicBreakfast2',
        name: 'Classic Breakfast 2',
        price: 8.45,
        description: '1 x Mini croissant/Danish pastry per person, 1 x Mini fruit salad pot (5oz) per person, 1 x Regular Size filled breakfast bagel per person',
        imageUrl: 'https://staging.fedras.co.uk/wp-content/uploads/2024/08/classic-breakfast-2-600x596.jpg',
        fillings: {
          'Smoked Salmon & Cream Cheese': ['E', 'F', 'G', 'M', 'S'],
          'Gammon Ham & Emmental': ['E', 'G', 'M', 'S'],
          'Brie Cheese, Cranberry Jam & Baby Leaf Spinach': ['E', 'G', 'M', 'S', 'V'],
          'Mature Cheddar & Tomato': ['E', 'G', 'M', 'S', 'V'],
          'Hummus & Roasted Peppers': ['G', 'SD', 'SS', 'VG']
        }
      },
      {
        id: 'classicBreakfast3',
        name: 'Classic Breakfast 3',
        price: 8.45,
        description: '1 x Greek yogurt pot with granola and topped with honey & blueberries per person, 1 x Fresh fruit skewer per person, 1 x Freshly squeezed orange juice (250ml) per person',
        imageUrl: 'https://staging.fedras.co.uk/wp-content/uploads/2024/08/classic-breakfast-3-600x600.jpg'
      },
      {
        id: 'croissants',
        name: 'Croissants',
        price: 2.90,
        description: 'Available as Plain, Almond or Chocolate (minimum 4 Items)',
        imageUrl: 'https://staging.fedras.co.uk/wp-content/uploads/2024/08/Croissants-600x600.jpg',
        minQuantity: 4,
        options: ['Plain', 'Almond', 'Chocolate']
      },
      {
        id: 'danishPastries',
        name: 'Danish Pastries',
        price: 2.90,
        description: '(minimum 4 Items)',
        imageUrl: 'https://staging.fedras.co.uk/wp-content/uploads/2024/08/danish-pastries-600x600.jpg',
        minQuantity: 4
      }
    ],
    lunch: [
      {
        id: 'classicLunch1',
        name: 'Classic Finger Buffets 1',
        price: 15.25,
        description: 'A selection of Sands Classic Sandwiches, Rolls and wraps, Southern Fried Chicken Goujons with a Sweet Chilli Dip, Crispy Butterfly Prawns with a Sweet Chilli Dip, Spiced Vegetable Samosas with a Mango Chutney Dip, Fresh Fruit Skewers, Home made cakes selection',
        imageUrl: 'https://staging.fedras.co.uk/wp-content/uploads/2024/08/finger-1-1-600x600.jpg',
        fillings: {
          'Chicken & Bacon': ['E', 'G', 'M'],
          'Tuna Mayo': ['E', 'F', 'G', 'M'],
          'Egg Mayo': ['E', 'G', 'M'],
          'Cheese & Pickle': ['G', 'M', 'V']
        }
      },
      {
        id: 'classicLunch2',
        name: 'Classic Finger Buffets 2',
        price: 15.25,
        description: 'A selection of Sands Classic Sandwiches, Rolls and wraps, Cajun Chicken with Chipotle Mayo, Ham & Cheddar Quiche, Vegetable Spring Rolls with a Sweet Chili Dip, Fresh Fruit Skewers, Lemon Meringue Tarts',
        imageUrl: 'https://staging.fedras.co.uk/wp-content/uploads/2024/08/finger-2-600x600.jpg',
        fillings: {
          'Ham & Cheese': ['E', 'G', 'M'],
          'Veggie Supreme': ['G', 'V'],
          'BLT': ['E', 'G', 'M'],
          'Hummus & Falafel': ['G', 'VG']
        }
      },
      {
        id: 'classicLunch3',
        name: 'Classic Finger Buffets 3',
        price: 15.25,
        description: 'A selection of Sands Classic Sandwiches, Rolls and wraps, Mango Curry Chicken with Mint Yogurt Raita, Crispy Butterfly Prawns with a Sweet Chilli Dip, Mini Spinach & Parmesan Florentine Quiches, Fresh mini fruit skewers, A selection of Sands Classic home made cakes',
        imageUrl: 'https://staging.fedras.co.uk/wp-content/uploads/2024/08/finger-3-600x600.jpg',
        fillings: {
          'Smoked Salmon': ['F', 'G', 'M'],
          'Roast Beef & Horseradish': ['E', 'G', 'M'],
          'Mediterranean Veg': ['G', 'V'],
          'Coronation Chicken': ['E', 'G', 'M']
        }
      }
    ],
    drinks: [
      { id: 'stillWater', name: 'Still Water (500ml)', price: 1.75, imageUrl: 'https://example.com/still_water.jpg' },
      { id: 'sparklingWater', name: 'Sparkling Water (500ml)', price: 1.75, imageUrl: 'https://example.com/sparkling_water.jpg' },
      { id: 'breakfastOrangeJuice', name: 'Breakfast Orange Juice', price: 3.50, imageUrl: 'https://example.com/orange_juice.jpg' },
      { id: 'coke', name: 'Coke', price: 1.75, imageUrl: 'https://example.com/coke.jpg' },
      { id: 'dietCoke', name: 'Diet Coke', price: 1.75, imageUrl: 'https://example.com/diet_coke.jpg' },
      { id: 'fanta', name: 'Fanta', price: 1.75, imageUrl: 'https://example.com/fanta.jpg' },
      { id: '7up', name: '7 UP', price: 1.75, imageUrl: 'https://example.com/7up.jpg' },
      { id: 'sprite', name: 'Sprite', price: 1.75, imageUrl: 'https://example.com/sprite.jpg' }
    ],
    extras: [
      { 
        id: 'nutsAndNibbles', 
        name: 'Nuts & Nibbles', 
        price: 5.00, 
        minQuantity: 5, 
        description: '(SHARING BOX) (MIN 5 PEOPLE)', 
        imageUrl: 'https://staging.fedras.co.uk/wp-content/uploads/2024/09/marinated-olives-with-nachos-and-dips.jpeg' 
      },
      { 
        id: 'nachos', 
        name: 'Nachos with Guacamole, Spicy Salsa & Sour Cream', 
        price: 5.00, 
        minQuantity: 5, 
        description: '(SHARING BOX) (MIN 5 PEOPLE)', 
        imageUrl: 'https://staging.fedras.co.uk/wp-content/uploads/2024/09/Nachos.jpg' 
      },
      { 
        id: 'olives', 
        name: 'Marinated Olives with Nachos & Dips', 
        price: 5.00, 
        minQuantity: 5, 
        description: '(SHARING BOX) (MIN 5 PEOPLE)', 
        imageUrl: 'https://staging.fedras.co.uk/wp-content/uploads/2024/09/nibbles-platter.jpg' 
      }
    ]
  };

  useEffect(() => {
    calculateTotalPrice();
  }, [order]);

  useEffect(() => {
    if (orderSummaryRef.current) {
      setOrderSummaryHeight(orderSummaryRef.current.offsetHeight);
    }
  }, [order, totalPrice]);

  const updateQuantity = (itemId, value) => {
    setOrder(prevOrder => ({
      ...prevOrder,
      [itemId]: {
        ...prevOrder[itemId],
        quantity: value,
        fillings: prevOrder[itemId].fillings ? Object.fromEntries(
          Object.entries(prevOrder[itemId].fillings).map(([filling, qty]) => [filling, 0])
        ) : undefined,
        options: prevOrder[itemId].options ? Object.fromEntries(
          Object.entries(prevOrder[itemId].options).map(([option, qty]) => [option, 0])
        ) : undefined
      }
    }));
  };

  const updateFilling = (itemId, filling, value) => {
    setOrder(prevOrder => ({
      ...prevOrder,
      [itemId]: {
        ...prevOrder[itemId],
        fillings: {
          ...prevOrder[itemId].fillings,
          [filling]: value
        }
      }
    }));
  };

  const updateOption = (itemId, option, value) => {
    setOrder(prevOrder => ({
      ...prevOrder,
      [itemId]: {
        ...prevOrder[itemId],
        options: {
          ...prevOrder[itemId].options,
          [option]: value
        }
      }
    }));
  };

  const calculateTotalPrice = () => {
    let total = 0;

    [...menuItems.breakfast, ...menuItems.lunch].forEach(item => {
      total += item.price * (order[item.id].quantity || 0);
    });

    Object.entries(order.drinks).forEach(([itemId, quantity]) => {
      const drink = menuItems.drinks.find(item => item.id === itemId);
      if (drink) {
        total += drink.price * quantity;
      }
    });

    Object.entries(order.extras).forEach(([itemId, quantity]) => {
      const extra = menuItems.extras.find(item => item.id === itemId);
      if (extra) {
        total += extra.price * quantity;
      }
    });

    setTotalPrice(total);
  };

  const validateOrder = () => {
    let errors = [];
  
    const totalBreakfastQuantity = menuItems.breakfast.reduce((sum, item) => sum + (order[item.id].quantity || 0), 0);
    if (totalBreakfastQuantity > 0 && totalBreakfastQuantity < 4) {
      errors.push("Please order at least 4 breakfast items in total if ordering breakfast.");
    }
  
    [...menuItems.breakfast, ...menuItems.lunch].forEach(item => {
      if (item.fillings && order[item.id].quantity > 0) {
        const totalFillings = Object.values(order[item.id].fillings).reduce((sum, value) => sum + (value || 0), 0);
        if (totalFillings !== order[item.id].quantity) {
          errors.push(`Please select exactly ${order[item.id].quantity} fillings for ${item.name}.`);
        }
      }
    });
  
    if (order.croissants.quantity > 0) {
      const totalOptions = Object.values(order.croissants.options).reduce((sum, value) => sum + (value || 0), 0);
      if (totalOptions !== order.croissants.quantity) {
        errors.push(`Please select exactly ${order.croissants.quantity} options for Croissants.`);
      }
    }
  
    if (order.croissants.quantity > 0 && order.croissants.quantity < 4) {
      errors.push("Minimum order for Croissants is 4 items.");
    }
    if (order.danishPastries.quantity > 0 && order.danishPastries.quantity < 4) {
      errors.push("Minimum order for Danish Pastries is 4 items.");
    }
  
    const totalLunchQuantity = menuItems.lunch.reduce((sum, item) => sum + (order[item.id].quantity || 0), 0);
    if (totalLunchQuantity > 0 && totalLunchQuantity < 4) {
      errors.push("Please order at least 4 lunch items if you're ordering lunch.");
    }
  
    menuItems.extras.forEach(item => {
      const extraQuantity = order.extras[item.id] || 0;
      if (extraQuantity > 0 && extraQuantity < item.minQuantity) {
        errors.push(`Minimum order for ${item.name} is ${item.minQuantity}.`);
      }
    });
  
    if (errors.length > 0) {
      throw new Error(errors.join("\n"));
    }
  };

  const handleCheckout = () => {
    try {
      validateOrder();
      setShowCheckout(true);
    } catch (error) {
      setError(error.message);
      setSnackbarOpen(true);
    }
  };

  const handlePaymentSuccess = () => {
    setSuccessMessage('Payment successful! Your order has been placed.');
    setSnackbarOpen(true);
    setShowCheckout(false);
    setOrder({
      classicBreakfast1: { quantity: 0, fillings: {} },
      classicBreakfast2: { quantity: 0, fillings: {} },
      classicBreakfast3: { quantity: 0 },
      croissants: { quantity: 0, options: {} },
      danishPastries: { quantity: 0 },
      classicLunch1: { quantity: 0, fillings: {} },
      classicLunch2: { quantity: 0, fillings: {} },
      classicLunch3: { quantity: 0, fillings: {} },
      drinks: {},
      extras: {}
    });
  };

  const handlePaymentError = (errorMessage) => {
    setError(errorMessage);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
    setError('');
    setSuccessMessage('');
  };

  const handleImageClick = (imageUrl) => {
    setZoomedImage(imageUrl);
  };

  return (
    <ThemeProvider theme={theme}>
      <Header logoUrl={logoUrl} onAllergenAdviceClick={() => setAllergenAdviceOpen(true)} />
      <Container className='content-container' maxWidth="lg">
        <Typography mt={4} variant="h4" gutterBottom color="primary">Fedras Meeting Room Food Order</Typography>
        <Typography variant="subtitle1" gutterBottom color="secondary">Order by 5:30 pm for next-day orders.</Typography>
  
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            {!showCheckout ? (
              <form>
                <MenuSection
                  title="Breakfast Menu"
                  items={menuItems.breakfast}
                  order={order}
                  updateQuantity={updateQuantity}
                  updateFilling={updateFilling}
                  updateOption={updateOption}
                  onImageClick={handleImageClick}
                />
                <Divider sx={{ my: 4 }} />
                <MenuSection
                  title="Lunch Menu"
                  items={menuItems.lunch}
                  order={order}
                  updateQuantity={updateQuantity}
                  updateFilling={updateFilling}
                  onImageClick={handleImageClick}
                />
                <Divider sx={{ my: 4 }} />
                <Box mb={4}>
                  <Typography variant="h5" gutterBottom color="primary">🥤 Drinks</Typography>
                  {menuItems.drinks.map((drink, index) => (
                    <React.Fragment key={drink.id}>
                      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                        <Typography>{drink.name} - <PriceTypography component="span">£{drink.price.toFixed(2)}</PriceTypography></Typography>
                        <QuantityInput
                          value={order.drinks[drink.id] || 0}
                          onChange={(value) => setOrder(prevOrder => ({
                            ...prevOrder,
                            drinks: { ...prevOrder.drinks, [drink.id]: value }
                          }))}
                        />
                      </Box>
                      {index < menuItems.drinks.length - 1 && <Divider sx={{ my: 1 }} />}
                    </React.Fragment>
                  ))}
                </Box>
                <Divider sx={{ my: 4 }} />
                <Box mb={4}>
                  <Typography variant="h5" gutterBottom color="primary">🍽️ Extras</Typography>
                  {menuItems.extras.map((extra, index) => (
                    <React.Fragment key={extra.id}>
                      <StyledCard>
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item>
                              <ImageContainer onClick={() => handleImageClick(extra.imageUrl)}>
                                <CardMedia
                                  component="img"
                                  height="100"
                                  image={extra.imageUrl}
                                  alt={extra.name}
                                />
                                <ZoomIconOverlay className="zoom-icon">
                                  <ZoomInIcon style={{ color: 'white' }} />
                                </ZoomIconOverlay>
                              </ImageContainer>
                            </Grid>
                            <Grid item xs>
                              <Typography variant="h6" color="primary">{extra.name}</Typography>
                              <PriceTypography variant="subtitle1">£{extra.price.toFixed(2)} per person</PriceTypography>
                              <Typography variant="body2" color="text.secondary" gutterBottom>{extra.description}</Typography>
                              <Box display="flex" justifyContent="flex-end" mt={1}>
                                <QuantityInput
                                  value={order.extras[extra.id] || 0}
                                  onChange={(value) => setOrder(prevOrder => ({
                                    ...prevOrder,
                                    extras: { ...prevOrder.extras, [extra.id]: value }
                                  }))}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </StyledCard>
                      {index < menuItems.extras.length - 1 && <Divider sx={{ my: 2 }} />}
                    </React.Fragment>
                  ))}
                </Box>
              </form>
            ) : (
              <Elements stripe={stripePromise}>
                <CheckoutForm
                  order={order}
                  menuItems={menuItems}
                  totalPrice={totalPrice}
                  onSuccess={handlePaymentSuccess}
                  onError={handlePaymentError}
                />
              </Elements>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ position: 'sticky', top: 20, zIndex: 1 }}>
              <FloatingBox>
                <OrderSummary
                  order={order}
                  menuItems={menuItems}
                  totalPrice={totalPrice}
                  onCheckout={handleCheckout}
                  isCheckoutDisabled={totalPrice === 0}
                />
              </FloatingBox>
              <FloatingBox sx={{ mt: 2 }}>
                <AllergenInfoBox />
              </FloatingBox>
            </Box>
          </Grid>
        </Grid>
  
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleSnackbarClose} severity={error ? "error" : "success"} sx={{ width: '100%' }}>
            {error || successMessage}
          </Alert>
        </Snackbar>
  
        <Modal
          open={!!zoomedImage}
          onClose={() => setZoomedImage(null)}
          aria-labelledby="zoom-image"
          aria-describedby="zoomed in product image"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}>
            <img src={zoomedImage} alt="Zoomed product" style={{ maxWidth: '100%', maxHeight: '80vh' }} />
          </Box>
        </Modal>
  
        <Dialog
          open={allergenAdviceOpen}
          onClose={() => setAllergenAdviceOpen(false)}
          aria-labelledby="allergen-advice-title"
          aria-describedby="allergen-advice-description"
        >
          <DialogTitle id="allergen-advice-title">Allergen Advice</DialogTitle>
          <DialogContent>
            <DialogContentText id="allergen-advice-description">
              {allergenAdviceText}
            </DialogContentText>
          </DialogContent>
          <Button onClick={() => setAllergenAdviceOpen(false)} color="primary">
            Close
          </Button>
        </Dialog>
      </Container>
      <Footer logoUrl={logoUrl} onAllergenAdviceClick={() => setAllergenAdviceOpen(true)} />
    </ThemeProvider>
  );
};

export default OrderPage;